<template>
<button
    class="button-full"
    :class="{ 'button-full-disabled': disabled }"
    :disabled="disabled"
    @click="$emit('go-to-next-step')">{{text}}</button>
</template>

<script>
export default {
    name: "ButtonFull",
    props: {
        text: String,
        disabled: Boolean,
    },
}
</script>

<style lang="scss" scoped>
.button-full {
    min-width: 107px;
    padding: 9px 24px;
    border-radius: 4px;
    background-color: #444444;
    border: none;
    font-size: 13px;
    font-weight: 500;
    color: #fff;

    &-disabled {
        opacity: .5;
    }
}
</style>
