<template>
<b-modal :id="type"
         :title="mode === 'edit' ? $t('pass.edit') : $t('pass.create')"
         ref="modal"
         size="lg"
         hide-footer
         modal-class="fixed-right"
         dialog-class="modal-dialog-vertical pass-create-dialog"
         @hide="hideModal"
         @show="showModal"
>
    <div class="pass-create-modal-body">
        <Steps :list="steps" @set-step="setStep" />
        <div class="pass-create-modal-step">
            <StepDesign v-if="currentStep === 'design'" :errors="errors" />
            <StepDoors v-if="currentStep === 'doors'" :errors="errors" />
            <StepName v-if="currentStep === 'name'" :errors="errors" />
            <StepUsers v-if="currentStep === 'users'" :errors="errors" />
        </div>
        <StepsFooter
            :is-first="currentStep === 'name'"
            :is-last="currentStep === 'design'"
            @go-to-previous-step="$emit('go-to-previous-step')"
            @go-to-next-step="$emit('go-to-next-step')"
            @prev-step="toPrevStep"
            @next-step="toNextStep"
            @send-passport="sendPassport"
            :errors="errors"
        />
    </div>
</b-modal>
</template>

<script>
import { M_PASS_FORM } from "@/components/modals/types";
import Steps from "@/components/pass/Steps.vue";
import StepDesign from "@/components/pass/StepDesign";
import StepDoors from "@/components/pass/StepDoors";
import StepName from "@/components/pass/StepName";
import StepUsers from "@/components/pass/StepUsers";
import StepsFooter from "@/components/pass/StepsFooter.vue";
import {
    SET_INITIAL_ACTIVE_PASSPORT,
    SET_SENDING,
    ERROR_NAME,
    ERROR_NAME_LENGTH,
    ERROR_VALIDITY,
    ERROR_DOORS,
    ERROR_IMAGE_TITLE,
} from "@/store/types/passport";
import {SHOW_NOTIFY} from "@/store/types/global";
import PassportController from '../../api/passport';

export default {
    data() {
        return {
            type: M_PASS_FORM,
            passport: null,
            steps: [],
            errors: [],
        }
    },
    name: "PassCreate",
    components: {
        Steps,
        StepDesign,
        StepDoors,
        StepName,
        StepUsers,
        StepsFooter,
    },
    computed: {
        currentStep() {
            return this.steps.find(s => s.isCurrent)?.id
        },
        activePassport() {
            return this.$store.getters.getActivePassport;
        },
        initialStep() {
            return this.$store.getters.getActiveStep;
        },
        companyId() {
            return this.$store.getters.getActiveCompany.Id;
        },
        locationId() {
            return this.$store.getters.getActiveLocation.Id;
        },
        mode() {
            return this.$store.getters.getPassMode;
        },
    },
    methods: {
        setSending(value) {
            this.$store.commit(SET_SENDING, value);
        },
        validateStep(id) {
            switch(id) {
                case 'name':
                    return this.validateName();
                case 'doors':
                    return this.passport.Devices?.length ? [] : [ERROR_DOORS];
                case 'design':
                    return this.validateImageTitle();
                default:
                    return [];
            }
        },
        validateImageTitle() {
            const result = [];
            const parameters = this.passport?.LayoutParameters ?? "{}";
            const imageTitle = JSON.parse(parameters).title;
            if (imageTitle?.length > 50) {
                result.push(ERROR_IMAGE_TITLE);
            }
            return result;
        },
        validateName() {
            const result = [];
            const name = this.passport.Name;
            if (!name) {
                result.push(ERROR_NAME);
            } else if (name.length < 3 || name.length > 30) {
                result.push(ERROR_NAME_LENGTH);
            }
            if (isNaN(parseInt(this.passport.ValidityTimeMinute)) || parseInt(this.passport.ValidityTimeMinute) < 5) {
                result.push(ERROR_VALIDITY);
            }
            return result;
        },
        setStep(id) {
            if (this.steps.find(s => s.id === id).isActive) {
                this.steps = this.steps.map(s => ({ ...s, isCurrent: s.id === id}))
            }
        },
        showModal() {
            const initialSteps = [
                {
                    isActive: this.mode === 'edit',
                    isCurrent: false,
                    text: this.$t('pass.step1.label'),
                    id: 'name',
                },
                {
                    isActive: false,
                    isCurrent: false,
                    text: this.$t('pass.step2.label'),
                    id: 'doors',
                },
                {
                    isActive: false,
                    isCurrent: false,
                    text: this.$t('pass.step3.label'),
                    id: 'users',
                },
                {
                    isActive: false,
                    isCurrent: false,
                    text: this.$t('pass.step4.label'),
                    id: 'design',
                },
            ];
            initialSteps.find(s => s.id === this.initialStep).isCurrent = true;
            initialSteps.forEach(s => {
                if (s.id !== this.initialStep && this.mode === 'edit') {
                    s.isActive = true;
                }
            });
            this.steps = initialSteps;
            this.passport = this.$store.getters.getActivePassport;
            this.setActiveSteps();
        },
        hideModal() {
            this.steps = [];
            this.passport = null;
            this.$store.commit(SET_INITIAL_ACTIVE_PASSPORT);
            this.$emit('hide-modal');
        },
        toStep(step) {
            this.steps.forEach(s => {
                s.isCurrent = s.id === step
            })
        },
        showErrorMessage(message) {
            this.$store.commit(SHOW_NOTIFY, {
                text: message,
                title: "Error",
                variant: "danger"
            });
        },
        toPrevStep() {
            const current = this.steps.findIndex(s => s.isCurrent);
            if (current === -1) return;
            this.steps[current].isCurrent = false;
            this.steps[current - 1].isCurrent = true;
        },
        async sendPassport() {
            if (this.passport.Devices.length === 0) {
                this.showErrorMessage(this.$t('pass.error.doors'));
                this.toStep('doors');
            }
            if (!this.passport.Name) {
                this.showErrorMessage(this.$t('pass.error.name'));
                this.toStep('name');
            }
            if (!this.locationId) {
                this.showErrorMessage('Location ID required');
            }
            if (!this.companyId) {
                this.showErrorMessage('Company ID required');
            }
            const errors = this.validateStep(this.currentStep);
            this.errors = errors;
            if (errors.length === 0) {
                const isUpdating = !!this.passport.Id
                try {
                    const body = window.structuredClone(this.passport);
                    body.LocationId = this.locationId;
                    body.CompanyId = this.companyId;
                    if (isUpdating) {
                        await PassportController.updatePassport(body);
                    } else {
                        delete body.Id;
                        await PassportController.createPassport(body);
                    }
                    this.$store.commit(SHOW_NOTIFY, {
                        text: isUpdating ? this.$t('pass.isUpdated') : this.$t('pass.isCreated'),
                        title: "Success",
                        variant: "success"
                    });
                    this.$refs['modal'].hide();
                    this.setSending(false);
                } catch(error) {
                    this.showErrorMessage(error.response.data.error);
                    this.setSending(false);
                }
            } else {
                this.setSending(false);
            }
        },
        toNextStep() {
            const errors = this.validateStep(this.currentStep);
            this.errors = errors;
            if (errors.length === 0) {
                const current = this.steps.findIndex(s => s.isCurrent);
                if (current === -1) return;
                this.steps[current].isCurrent = false;
                this.steps[current].isActive = true;
                this.steps[current + 1].isCurrent = true;
            }
        },
        setActiveSteps() {
            if (this.steps?.length && this.passport?.Devices.length > 0 && this.currentStep !== 'doors') {
                this.steps.find(s => s.id = 'doors').isActive = true;
            }
            if (this.steps?.length && this.passport?.Users.length > 0 && this.currentStep !== 'users') {
                this.steps.find(s => s.id = 'users').isActive = true;
            }
            if (this.steps?.length && this.passport?.Name && this.currentStep !== 'name') {
                this.steps.find(s => s.id = 'name').isActive = true;
            }
        },
    },
    watch: {
        activePassport: {
            handler(pass) {
                this.errors = [];
                this.setActiveSteps();
                this.passport = window.structuredClone(pass);
            },
            deep: true,
        },
    },
}
</script>

<style lang="scss">
.pass-create {

    &-dialog > div {
        padding: 0 50px;
    }

    &-modal {

        &-body {
            display: flex;
            flex-direction: column;
            width: 100%;
            min-height: 500px;
        }

        &-step {
            padding: 30px 0;
            flex-grow: 1;
        }
    }
}

@media (max-width: 480px) {
    .pass-create-dialog > div {
        padding: 0;
    }
}
</style>
