<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="size"
        :height="size"
        viewBox="0 0 10 10"
        fill="none"
    >
        <path
            d="M10 8.88889V1.11111C10 0.5 9.5 0 8.88889 0H1.11111C0.5 0 0 0.5 0 1.11111V8.88889C0 9.5 0.5 10 1.11111 10H8.88889C9.5 10 10 9.5 10 8.88889ZM3.05556 5.83333L4.44444 7.50556L6.38889 5L8.88889 8.33333H1.11111L3.05556 5.83333Z"
            fill="currentColor"/>
    </svg>
</template>

<script>
export default {
    name: "IconImage",
    props: {
        size: {
            type: Number,
            default: 18,
        },
    },
}
</script>
