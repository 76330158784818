<template>
<div class="button-link" @click="$emit('go-to-previous-step')">
    <IconArrowLeft :size="18" />
    <span>{{text}}</span>
</div>
</template>

<script>
import IconArrowLeft from "@/components/common/icons/IconArrowLeft.vue";

export default {
    name: "ButtonLink",
    props: {
        text: String,
        icon: Object,
    },
    components: {
        IconArrowLeft,
    },
}
</script>

<style lang="scss" scoped>
.button-link {
    display: flex;
    align-items: center;
    color: #E95325;
    cursor: pointer;

    span {
        margin-left: 5px;
        font-size: 13px;
        font-weight: 500;
    }
}
</style>
