<template>
<div class="steps-footer">
    <div class="steps-footer-left">
        <ButtonLink v-if="!isFirst" :text="$t('pass.buttonBack')" @click.native="$emit('prev-step')" />
    </div>
    <div class="steps-footer-right">
        <ButtonFull
            v-if="isLast"
            :text="mode === 'edit' ? $t('pass.edit') : $t('pass.create')"
            @click.native="create"
            :disabled="sending || errors.length > 0" />
        <ButtonFull
            v-else :text="$t('pass.buttonNext')"
            @click.native="next"
            :disabled="errors.length > 0"
        />
    </div>
</div>
</template>

<script>
import ButtonLink from "@/components/common/ButtonLink.vue";
import ButtonFull from "@/components/common/ButtonFull.vue";
import { SET_SENDING } from "@/store/types/passport";

export default {
    name: "StepsFooter",
    props: {
        isFirst: Boolean,
        isLast: Boolean,
        errors: [],
    },
    components: {
        ButtonLink,
        ButtonFull,
    },
    computed: {
        mode() {
            return this.$store.getters.getPassMode;
        },
        sending() {
            return this.$store.getters.getSending;
        },
    },
    methods: {
        create() {
            this.$store.commit(SET_SENDING, true);
            this.$emit('send-passport');
        },
        next() {
            this.$emit('next-step');
        },
    },
}
</script>

<style lang="scss" scoped>
.steps-footer {
    display: flex;
    justify-content: space-between;

    &-left {
        display: flex;
        justify-content: flex-start;
        flex-grow: 1;
    }

    &-right {
        display: flex;
        justify-content: flex-end;
        flex-grow: 1;
    }
}
</style>
