<template>
    <div>
        <label class="typo__label">{{ $t('pass.step2.doors') }}</label>
        <SelectMultiselect
            v-if="selected && doorsOptions"
            :placeholder="$t('pass.step2.doorsPlaceholder')"
            :initial-selected="selected"
            :initial-options="doorsOptions"
            :error-message="isErrorDoors ? $t('pass.error.doors'): null"
            :selected-text-one="$t('pass.step2.selectedOne')"
            :selected-text-several="$t('pass.step2.selectedSeveral')"
            :not-found="$t('pass.step2.notFound')"
            with-order
            with-tags
            @multiselect-selected="doorsUpdated"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import SelectMultiselect from "@/components/common/SelectMultiselect.vue";
import {
    ERROR_DOORS,
    SET_ACTIVE_PASSPORT_DOORS,
} from "@/store/types/passport";

export default {
    name: "StepDoors",
    data() {
        return {
            selected: [],
            doorsOptions :[],
        }
    },
    props: {
        errors: [],
    },
    components: { SelectMultiselect },
    computed: {
        ...mapGetters(['getLocationDevices', 'getActivePassportDoors']),
        isErrorDoors() {
            return this.errors.includes(ERROR_DOORS);
        },
    },
    methods: {
        doorsUpdated(list) {
            this.$store.commit(
                SET_ACTIVE_PASSPORT_DOORS,
                list.map(door => door.id)
            );
        },
    },
    mounted() {
        this.doorsOptions = this.getLocationDevices.map(door => ({ id: door.Device.Id, label: door.Device.Name }));
        this.doorsOptions.sort();
        const ids = this.getActivePassportDoors
        this.selected = this.doorsOptions.filter(item => ids.find(id => item.id === id));
    },
}
</script>

<style lang="scss">

</style>
