<template>
<div v-if="text" class="error-message">
    <IconInfo :size="10" />
    <span class="error-message-text">{{ text }}</span>
</div>
</template>

<script>
import IconInfo from "@/components/common/icons/IconInfo.vue";

export default {
    name: "errorMessage",
    props: {
        text: null,
    },
    components: { IconInfo }
}
</script>

<style lang="scss" scoped>
.error-message {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
    font-size: 12px;
    line-height: 16px;
    color: #D93F49;
}
</style>
