<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="size"
        :height="size"
        viewBox="0 0 14 14"
        fill="none"
    >
        <g>
            <path
                d="M4 11.917V14H6.08304L12.2266 7.85641L10.1436 5.77336L4 11.917ZM13.8375 6.24552C14.0542 6.02888 14.0542 5.67893 13.8375 5.4623L12.5377 4.16248C12.3211 3.94584 11.9711 3.94584 11.7545 4.16248L10.738 5.179L12.821 7.26205L13.8375 6.24552Z"
                fill="currentColor"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: "IconPen",
    props: {
        size: {
            type: Number,
            default: 18,
        },
    },
}
</script>
