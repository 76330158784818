<template>
    <b-container class="step-design">
        <b-row>
            <b-col>
                <div class="step-design-file">
                    <b-file
                        ref="step-design-file"
                        id="step-design-file"
                        v-model="loadedFile"
                        :placeholder="$t('pass.step4.drag')"
                        :browse-text="$t('pass.step4.chooseImage')"
                        :class="{ 'step-design-file-added': image }"
                    />
                    <div v-if="image" class="step-design-file-image">
                        <img :src="image" alt="image">
                    </div>
                    <button
                        v-if="image"
                        class="step-design-file-button"
                        @click="clearImage">{{ $t('pass.step4.reset') }}</button>
                </div>
                <p>{{ $t('pass.step4.image.text') }}</p>
                <p class="m-0">{{ $t('pass.step4.image.specs') }}</p>
                <p class="m-0">{{ $t('pass.step4.image.dimension') }}</p>
                <p class="m-0">{{ $t('pass.step4.image.size') }}</p>
                <p class="m-0">{{ $t('pass.step4.image.type') }}</p>
                <div class="step-design-field">
                    <label for="step-design-text">{{ $t('pass.step4.text.label') }}</label>
                    <b-form-input
                        type="text"
                        v-model="loaded.title"
                        :state="!isErrorImageTitle"
                        id="step-design-text"
                        placeholder="Choose a title text" />
                    <ErrorMessage v-if="isErrorImageTitle" :text="$t('pass.step4.text.error')" />
                </div>
                <div class="step-design-field">
                    <label id="step-design-color">{{ $t('pass.step4.color') }}</label>
                    <b-form-input
                        type="color"
                        size="sm"
                        v-model="loaded.titleColor"
                        class="step-design-color"
                        id="step-design-color" />
                </div>
                <div class="step-design-field">
                    <label id="step-design-size">{{ $t('pass.step4.size') }} {{loaded.titleSize}}px</label>
                    <div class="step-design-size-wrapper">
                        <div class="step-design-size-legend">
                            <span>{{ sizeMin }}px</span>
                            <span>{{ sizeMax }}px</span>
                        </div>
                        <b-form-input
                            type="range"
                            v-model="loaded.titleSize"
                            :min="sizeMin"
                            :max="sizeMax"
                            id="step-design-size" />
                    </div>
                </div>
            </b-col>
            <b-col class="step-design-right">
                <div>
                    <h3 class="step-design-template-title">{{ $t('pass.step4.preview') }}</h3>
                    <div class="step-design-template">
                        <img
                            :src="templateImage"
                            alt="template image"
                            class="step-design-template-image">
                        <div class="step-design-template-content">
                            <img v-if="image" class="step-design-template-content-image" :src="image" alt="loaded image">
                            <div
                                v-if="loaded.title"
                                class="step-design-template-content-title"
                                :style="{ color: loaded.titleColor, fontSize: `${loaded.titleSize}px` }">{{  loaded.title }}</div>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import templateImage from '@/assets/images/passport-template.png';
import PassportController from '../../api/passport';
import {
    ERROR_IMAGE_TITLE,
    SET_ACTIVE_PASSPORT_IMAGE,
    SET_ACTIVE_PASSPORT_LAYOUT,
    SET_SENDING,
} from "@/store/types/passport";
import {SHOW_NOTIFY} from "@/store/types/global";
import ErrorMessage from "@/components/common/errorMessage.vue";
import {mapGetters} from "vuex";

export default {
    name: "StepDesign",
    components: {ErrorMessage},
    data() {
        return {
            loadedFile: null,
            image: null,
            templateImage: templateImage,
            sizeMin: 12,
            sizeMax: 32,
            loaded: {
                title: '',
                titleColor: '#000',
                titleSize: 16,
            },
        }
    },
    props: {
        errors: [],
    },
    computed: {
        ...mapGetters(['getActivePassportValidity']),
        isErrorImageTitle() {
            return this.errors.includes(ERROR_IMAGE_TITLE);
        },
        activePassport() {
            return this.$store.getters.getActivePassport;
        },
        activeImage() {
            return this.activePassport.LargeImageUrl
                ?? this.activePassport.MediumImageUrl
                ?? this.activePassport.SmallImageUrl;
        },
        activeLayout() {
            const initial = {
                title: '',
                titleColor: '#000',
                titleSize: 16,
            };
            const layout = this.activePassport.LayoutParameters;
            if (!layout) return initial;
            try {
                return JSON.parse(layout);
            } catch(error) {
                this.$store.commit(SHOW_NOTIFY, {
                    text: error,
                    title: "Error",
                    variant: "danger"
                });
                return initial;
            }
        },
    },
    methods: {
        clearImage() {
            this.image = null;
            this.$refs['step-design-file'].reset();
            this.$store.commit(SET_ACTIVE_PASSPORT_IMAGE, null);
        },
    },
    mounted() {
        if (!this.image) {
            this.image = this.activeImage;
        }
        this.loaded = this.activeLayout;
    },
    watch: {
        loadedFile(file) {
            const img = window.structuredClone(file);
            const fileReader = new FileReader();
            this.$store.commit(SET_SENDING, true);
            fileReader.addEventListener('load', () => {
                this.image = fileReader.result;
                PassportController.setPassportImage(file)
                    .then(res => {
                        this.$store.commit(SET_SENDING, false);
                        this.$store.commit(SHOW_NOTIFY, {
                            text: 'Image added successfully.',
                            title: "Success",
                            variant: "success"
                        });
                        this.$store.commit(SET_ACTIVE_PASSPORT_IMAGE, res.data.Id);
                    })
                    .catch(error => {
                        this.$store.commit(SHOW_NOTIFY, {
                            text: error.response.data.error,
                            title: "Error",
                            variant: "danger"
                        });
                    });
            });
            fileReader.readAsDataURL(img);
        },
        activeImage(val) {
            this.image = val;
        },
        activeLayout(val) {
            this.loaded = val;
        },
        loaded: {
            handler(val) {
                this.$store.commit(SET_ACTIVE_PASSPORT_LAYOUT, JSON.stringify(val));
            },
            deep: true,
        },
    },
}
</script>

<style lang="scss">
.step-design {
    font-size: 12px;
    line-height: 16px;

    input,
    input:hover,
    input:focus,
    input:active {
        &.is-valid {
            border-color: #d2ddec;
        }
    }

    &-right {
        display: flex;
        justify-content: flex-end;
    }

    &-template {
        display: block;
        width: 237px;
        height: 478px;
        border-radius: 32px;
        overflow: hidden;
        position: relative;

        &-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &-content {
            display: block;
            width: 100%;
            height: 205px;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;

            &-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &-title {
                display: flex;
                width: 100%;
                height: 100%;
                align-items: center;
                padding: 10px 30px;
                font-size: 16px;
                line-height: 1.5em;
                font-weight: 700;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
            }

            &:after {
                content: '';
                display: block;
                width: 110%;
                height: 25px;
                background-color: #F5F3F5;
                position: absolute;
                left: 0;
                top: 100%;
                transform-origin: left top;
                transform: rotate(-5.5deg);
            }
        }
    }

    &-file {
        width: 240px;
        height: 175px;
        margin: 0 0 60px;
        position: relative;

        .b-form-file {
            display: block;
            width: 100%;
            height: 100%;
            background-color: #f3f3f5;
            border: 1px dashed #D9D9D9;
        }

        .custom-file-input {
            width: 0;
            height: 0;
            visibility: hidden;
            position: absolute;
            left: 0;
            top: 0;
        }

        .custom-file-label {
            display: block;
            width: 100%;
            height: 100%;
            padding: 0;
            background: none;
            border: none;
            overflow: visible;
            position: absolute;
            left: 0;
            top: 0;

            &:before {
                content: '';
                display: block;
                width: 44px;
                height: 54px;
                margin: auto;
                background: url(/images/file-load-decor.png) no-repeat center;
                background-size: contain;
                position: absolute;
                left: 0;
                right: 0;
                top: 50px;
            }

            &:after {
                width: 88px;
                height: 22px;
                margin: 0 auto;
                padding: 0;
                text-align: center;
                background-color: #444;
                border-radius: 4px;
                color: #fff;
                font-size: 12px;
                line-height: 22px;
                left: 76px;
                right: auto;
                top: calc(100% + 20px);
                bottom: auto;
                cursor: pointer;
            }
        }

        .form-file-text {
            display: block;
            width: 100%;
            text-align: center;
            position: absolute;
            bottom: 35px;
        }

        &-image {
            width: 100%;
            height: 100%;
            overflow: hidden;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        &-added {

            .custom-file-label:after {
                left: 20px;
            }

            .form-file-text {
                overflow: hidden;
            }
        }

        &-button {
            width: 88px;
            height: 22px;
            padding: 0;
            border-radius: 4px;
            background-color: #fff;
            border: 1px solid #8F8F8F;
            font-size: 12px;
            line-height: 20px;
            color: #8F8F8F;
            position: absolute;
            top: calc(100% + 20px);
            right: 20px;
        }
    }

    &-field {
        margin: 20px 0 0;
    }

    &-size {

        &-wrapper {
            width: 240px;
            position: relative;
        }

        &-legend {
            display: flex;
            justify-content: space-between;
        }
    }

    &-color {
        width: 240px;
    }
}
</style>
