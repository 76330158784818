<template>
    <div>
        <label class="typo__label">{{ $t('pass.step3.users') }}</label>
        <SelectMultiselect
            v-if="selected && usersOptions"
            :initial-selected="selected"
            :initial-options="usersOptions"
            :selected-text-one="$t('pass.step3.selectedOne')"
            :selected-text-several="$t('pass.step3.selectedSeveral')"
            :placeholder="$t('pass.step3.usersPlaceholder')"
            :not-found="$t('pass.step3.notFound')"
            with-images
            with-tags
            @multiselect-selected="usersUpdated"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import SelectMultiselect from "@/components/common/SelectMultiselect.vue";
import { SET_ACTIVE_PASSPORT_USERS } from "@/store/types/passport";
import IconSearch from "@/components/common/icons/IconSearch.vue";

export default {
    name: "StepUsers",
    data() {
        return {
            selected: [],
            usersOptions :[],
        }
    },
    props: {
        errors: [],
    },
    components: { SelectMultiselect },
    computed: {
        ...mapGetters(['getLocationMembers', 'getActivePassportUsers']),
    },
    methods: {
        usersUpdated(list) {
            this.$store.commit(
                SET_ACTIVE_PASSPORT_USERS,
                list.map(u => u.id)
            );
        },
    },
    mounted() {
        this.usersOptions = this.getLocationMembers
            .map(user => ({
                id: user.User.Id,
                label: user.User.Name,
                img: user.User.SmallImageUrl,
            }))
            .reduce((prev, curr) => {
                if (!prev?.find(i => i.id === curr.id)) {
                    prev.push(curr);
                }
                return prev;
            }, []);
        const ids = this.getActivePassportUsers
        this.selected = this.usersOptions.filter(item => ids.find(id => item.id === id));
    },
}
</script>

<style lang="scss">
</style>
