<template>
    <div>
        <b-form-group
            id="name-group"
            :label="$t('pass.step1.name')"
            label-for="name">
            <b-form-input
                id="name"
                :value="getActivePassportName"
                type="email"
                :state="!isErrorName && !isErrorNameLength"
                :placeholder="$t('pass.step1.namePlaceholder')"
                autocomplete="off"
                @input="setName"
            />
            <ErrorMessage v-if="isErrorName" :text="$t('pass.error.name')" />
            <ErrorMessage v-if="isErrorNameLength" :text="$t('pass.error.name_length')" />
        </b-form-group>
        <b-form-group
            id="validity-group"
            :label="$t('pass.step1.validity')"
            class="step-name-validity"
            label-for="validity">
            <div>
                <b-form-input
                    id="validity"
                    :value="getActivePassportValidity"
                    :state="!isErrorValidity"
                    type="number"
                    autocomplete="off"
                    :max="120"
                    @input="setValidity"
                />minutes
            </div>
            <ErrorMessage v-if="isErrorValidity" :text="$t('pass.error.validity')" />
        </b-form-group>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
    SET_ACTIVE_PASSPORT_NAME,
    SET_ACTIVE_PASSPORT_VALIDITY,
    ERROR_NAME,
    ERROR_NAME_LENGTH,
    ERROR_VALIDITY,
} from "@/store/types/passport";
import ErrorMessage from "@/components/common/errorMessage.vue";

export default {
    name: "StepName",
    components: {ErrorMessage},
    props: {
        errors: [],
    },
    computed: {
        ...mapGetters(['getActivePassportName', 'getActivePassportValidity']),
        isErrorName() {
            return this.errors.includes(ERROR_NAME);
        },
        isErrorNameLength() {
            return this.errors.includes(ERROR_NAME_LENGTH);
        },
        isErrorValidity() {
            return this.errors.includes(ERROR_VALIDITY);
        },
    },
    methods: {
        setName(value) {
            this.$store.commit(SET_ACTIVE_PASSPORT_NAME, value);
        },
        setValidity(value) {
            this.$store.commit(SET_ACTIVE_PASSPORT_VALIDITY, value);
        },
    },
}
</script>

<style lang="scss" scoped>
input,
input:hover,
input:focus,
input:active {
    &.is-valid {
        border-color: #d2ddec;
    }
}
.step-name-validity {
    display: inline-block;

    input {
        display: inline-block;
        margin: 0 15px 0 0;
        width: 50px;
        text-align: center;
        padding-left: 0;
        padding-right: 0;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number] {
        -moz-appearance: textfield;
    }
}
</style>
