<template>
    <svg
        :width="size"
        :height="size"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g>
            <path
                d="M14.0625 12.1875H13.1875L12.875 11.875C13.9375 10.625 14.5625 9.0625 14.5625 7.3125C14.5625 3.4375 11.4375 0.25 7.5 0.25C3.5625 0.25 0.5 3.4375 0.5 7.3125C0.5 11.1875 3.625 14.375 7.5625 14.375C9.3125 14.375 10.9375 13.75 12.125 12.6875L12.4375 13V13.875L17.875 19.3125L19.5 17.6875L14.0625 12.1875ZM7.5625 12.1875C4.875 12.1875 2.6875 10 2.6875 7.3125C2.6875 4.625 4.875 2.4375 7.5625 2.4375C10.25 2.4375 12.4375 4.625 12.4375 7.3125C12.4375 10 10.25 12.1875 7.5625 12.1875Z"
                fill="currentColor"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: "IconSearch",
    props: {
        size: {
            type: Number,
            default: 24,
        },
    },
}
</script>
