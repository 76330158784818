<template>
    <div class="main-content">
        <div class="header">
            <div class="container-fluid">
                <div class="header-body">
                    <div class="row align-items-center">
                        <div class="col">
                            <h6 class="header-pretitle">
                                {{ $t("locations.location") }}
                            </h6>
                            <h1 class="header-title">
                                {{ $t("pass.pass") }}
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-container fluid>
            <b-row>
                <b-col cols="12">
                    <div class="card">
                        <div class="card-header">
                            <div class="col-auto">
                                <b-button
                                    v-if="isPassAdmin"
                                    variant="primary"
                                    size="sm"
                                    class="passports-create"
                                    @click="openPassModal({ step: 'name', id: null, mode: 'create' })"
                                >
                                    {{ $t("pass.create") }}
                                </b-button>
                                <b-button
                                    v-if="isPassAdmin"
                                    variant="outline-danger"
                                    size="sm"
                                    class="passports-remove ml-4"
                                    :disabled="deletion || noCheckedPassports"
                                    @click="removePass"
                                >
                                    {{ $t("pass.remove") }}
                                </b-button>
                            </div>
                            <div class="col-auto">
                                <div class="passports-search-wrapper">
                                    <IconSearch :size="18" class="passports-search-icon" />
                                    <b-form-input
                                        type="text"
                                        size="sm"
                                        class="passports-search-input"
                                        v-model="searchQuery"
                                        :placeholder="$t('pass.search')"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div v-if="passports && passports.length" class="passports-list">
                                <div
                                    v-for="passport in passports"
                                    :key="passport.Id"
                                    class="passports-item"
                                >
                                    <b-form-checkbox
                                        v-if="isPassAdmin"
                                        :id="passport.Id + '-passport-checkbox'"
                                        v-model="passport.checked"
                                    />
                                    <div
                                        class="passports-item-image"
                                        @click="openPassModal({ step: 'design', id: passport.Id, mode: 'edit' })"
                                    >
                                        <img v-if="passport.SmallImageUrl" :src="passport.SmallImageUrl" :alt="passport.Name">
                                        <img v-else :src="require('@/assets/images/passport-default.jpg')" :alt="passport.Name">
                                        <div class="passports-item-image-hover">{{ $t('pass.edit2') }}</div>
                                    </div>
                                    <div class="passports-item-name"><span v-if="passport.Name">{{ passport.Name }}</span></div>
                                    <div
                                        v-if="showPassportError(passport.Devices) || showPassportError(passport.Users)"
                                        class="passports-item-notification-mobile"
                                    >
                                        <IconInfo :size="16" />
                                    </div>
                                    <div class="passports-item-last">
                                        <div class="passports-item-notification">
                                            <div v-if="showPassportError(passport.Devices)">
                                                <IconInfo :size="16" class="mr-1" />
                                                <span>There are no doors selected for this pass</span>
                                            </div>
                                            <div v-if="showPassportError(passport.Users)">
                                                <IconInfo :size="16" class="mr-1" />
                                                <span>{{ $t('pass.error.noUsers') }}</span>
                                            </div>
                                        </div>
                                        <ButtonToggle
                                            v-if="isPassAdmin"
                                            :is-active="isPassportActive(passport)"
                                            class="passports-item-toggle"
                                            @toggle-passport="togglePassport(passport)" />
                                        <b-button
                                            v-if="isPassAdmin"
                                            variant="primary"
                                            @click="openPassModal({ step: 'name', id: passport.Id, mode: 'edit' })"
                                            size="sm"
                                            class="passports-item-edit">{{ $t('pass.edit') }}</b-button>
                                        <div class="passports-item-tools">
                                            <IconTools :id="passport.Id" @click.native="openPopover(passport.Id)" />
                                            <b-popover
                                                :target="`${passport.Id}`"
                                                placement="bottomleft"
                                                :show="passport.Id === popover"
                                                triggers=""
                                            >
                                                <div class="passports-item-notification">
                                                    <div v-if="showPassportError(passport.Devices)">
                                                        <div class="passports-item-icon">
                                                            <IconInfo :size="14" />
                                                        </div>
                                                        <span>There are no doors selected for this pass</span>
                                                    </div>
                                                    <div v-if="showPassportError(passport.Users)">
                                                        <div class="passports-item-icon">
                                                            <IconInfo :size="14" />
                                                        </div>
                                                        <span>{{ $t('pass.error.noUsers') }}</span>
                                                    </div>
                                                </div>
                                                <div class="passports-item-controls">
                                                    <div @click="editPassMobile(passport.Id)">
                                                        <div class="passports-item-icon">
                                                            <IconPen :size="18" />
                                                        </div>
                                                        <span>{{ $t('pass.edit') }}</span>
                                                    </div>
                                                    <div @click="editPassImageMobile(passport.Id)">
                                                        <div class="passports-item-icon">
                                                            <IconImage :size="14" />
                                                        </div>
                                                        <span>{{ $t('pass.step4.reset') }}</span>
                                                    </div>
                                                </div>
                                                <ButtonToggle
                                                    v-if="isPassAdmin"
                                                    :is-active="isPassportActive(passport)"
                                                    class="passports-item-toggle"
                                                    @toggle-passport="togglePassport(passport)" />
                                            </b-popover>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="passports-empty">
                                <img :src="require('@/assets/images/no-pass_woman_Zesec.png')" :alt="$t('pass.empty')">
                                <span>{{$t('pass.noPasses')}}</span>
                            </div>
                        </div>
                        <PassCreateModal @hide-modal="loadPassports" />
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import PassCreateModal from "@/components/pass/PassCreateModal";
import { M_PASS_FORM } from "@/components/modals/types";
import { DEVICES_GET } from "@/store/types/devices";
import {
    ACTION_ACTIVATE_PASSPORT,
    SET_INITIAL_ACTIVE_PASSPORT,
    ACTION_DEACTIVATE_PASSPORT,
    ACTION_GET_ALL_PASSPORTS,
    ACTION_GET_PASSPORT,
    SET_ACTIVE_STEP,
    SET_PASS_MODE,
} from "@/store/types/passport";
import IconSearch from "@/components/common/icons/IconSearch";
import IconPen from "@/components/common/icons/IconPen";
import IconImage from "@/components/common/icons/IconImage";
import IconTools from "@/components/common/icons/IconTools";
import IconInfo from "@/components/common/icons/IconInfo";
import ButtonToggle from "@/components/common/ButtonToggle";
import { nextTick } from "vue";
import PassportController from '../api/passport';
import {SHOW_NOTIFY} from "@/store/types/global";
import {LOCATION_MEMBERS_GET} from "@/store/types/members";

export default {
    name: "Pass",
    data() {
        return {
            popover: null,
            passports: null,
            searchQuery: '',
            deletion: false,
        }
    },
    components: {
        ButtonToggle,
        IconInfo,
        IconTools,
        IconSearch,
        IconPen,
        IconImage,
        PassCreateModal,
    },
    computed: {
        storePassports() {
            return this.$store.getters.getPassports;
        },
        noCheckedPassports() {
            return !this.passports?.find(p => p.checked);
        },
        isPassAdmin() {
            return this.$store.getters.getActiveUserRole !== 'KeyManager';
        },
    },
    methods: {
        isPassportActive(passport) {
            return !passport.DeactivatedAt;
        },
        openPassModal(data) {
            if (!this.isPassAdmin) {
                return;
            }
            const { step, id, mode } = data;
            this.$store.commit(SET_ACTIVE_STEP, step);
            this.$store.commit(SET_PASS_MODE, mode);
            if (id) {
                this.$store.dispatch(ACTION_GET_PASSPORT, id);
            } else {
                this.$store.commit(SET_INITIAL_ACTIVE_PASSPORT);
            }
            nextTick();
            this.$bvModal.show(M_PASS_FORM);
        },
        updatePassports(list) {
            const passports = this.searchQuery ? list.filter(p => p.Name.includes(this.searchQuery)) : list;
            this.passports = passports
                .map(p => {
                    const pass = window.structuredClone(p)
                    pass.checked = false
                    return pass;
                })
                .sort((prev, next) => {
                    if (prev.Name > next.Name) return 1;
                    if (prev.Name < next.Name) return -1;
                    return 0;
                });
        },
        showPassportError(list) {
            return !Array.isArray(list) || list.length === 0
        },
        togglePassport(passport) {
            this.isPassportActive(passport)
                ? this.$store.dispatch(ACTION_DEACTIVATE_PASSPORT, { passport: passport.Id, location: passport.LocationId})
                : this.$store.dispatch(ACTION_ACTIVATE_PASSPORT, { passport: passport.Id, location: passport.LocationId});
        },
        loadPassports() {
            const locationId = this.$route.params.locationId;
            this.$store.dispatch(ACTION_GET_ALL_PASSPORTS, locationId);
        },
        async removePass() {
            this.deletion = true;
            const checkedPassports = this.passports?.filter(p => p.checked).map(p => p.Id);
            if (checkedPassports?.length) {
                try {
                    await PassportController.deletePassport(checkedPassports);
                    this.loadPassports();
                    this.$store.commit(SHOW_NOTIFY, {
                        text: checkedPassports.length === 1 ? this.$t('pass.isDeleted') : this.$t('pass.areDeleted'),
                        title: "Success",
                        variant: "success"
                    });
                    await nextTick();
                } catch(error) {
                    this.$store.commit(SHOW_NOTIFY, {
                        text: error,
                        title: "Error",
                        variant: "danger"
                    });
                    this.deletion = false;
                }
            } else {
                this.deletion = false;
            }
        },
        editPassMobile(id) {
            this.popover = null;
            this.openPassModal({ step: 'name', id: id, mode: 'edit' });
        },
        editPassImageMobile(id) {
            this.popover = null;
            this.openPassModal({ step: 'design', id: id, mode: 'edit' });
        },
        openPopover(id) {
            this.popover = this.popover === id ? null : id;
        },
    },
    created() {
        const locationId = this.$route.params.locationId;
        this.$store.dispatch(DEVICES_GET, locationId);
        this.$store.dispatch(LOCATION_MEMBERS_GET, locationId);
        this.loadPassports();
    },
    watch: {
        storePassports(list) {
            this.updatePassports(list);
        },
        passports(list) {
            if (!list) { this.tools = null }
            this.tools = list.map(p => ({ id: p.Id, show: false }));
        },
        searchQuery() {
            this.updatePassports(this.storePassports);
        },
        noCheckedPassports(val) {
            if (!val) {
                this.deletion = false;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.card-body {
    display: flex;
    padding: 0 0 24px;
}
.card-header {
    height: auto;
    padding: 15px 12px;
}
.passports {
    &-list {
        width: 100%;
    }
    &-item {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 12px 24px;
        border-bottom: 1px solid #edf2f9;

        &:last-child {
            margin-bottom: 0;
        }

        &-image {
            width: 40px;
            height: 40px;
            margin: 0 15px 0 10px;
            background-color: #d9d9d9;
            border-radius: 4px;
            overflow: hidden;
            flex-shrink: 0;
            position: relative;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }

            &-hover {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                background-color: rgba(41, 41, 41, 0.55);
                opacity: 0;
                transition: .4s;
                font-size: 12px;
                color: #fff;
                position: absolute;
                left: 0;
                top: 0;
            }

            &:hover .passports-item-image-hover {
                opacity: 1;
            }
        }

        &-name {
            margin: 0 10px 0 15px;
        }

        &-notification {
            flex-shrink: 0;
            margin: 0 15px 0 10px;
            text-align: right;
            color: #D93F49;

            & > div {
                display: flex;
                align-items: center;
                padding: 0 5px;
                border-radius: 15px;
                background-color: #FFE3E6;

                svg {
                    flex-shrink: 0;
                }

                &:first-child {
                    margin: 0 0 5px;
                }
            }

            &-mobile {
                display: none;
            }
        }

        &-toggle {
            margin: 0 10px;
            text-transform: uppercase;
        }

        &-edit {
            flex-shrink: 0;
            background-color: #444;
            margin: 0 0 0 10px;
        }

        &-tools {
            display: none;
        }

        &-last {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-grow: 1;
        }
    }
    &-empty {
        width: 350px;
        height: 250px;
        margin: 50px auto;
        text-align: center;

        span {
            font-size: 10px;
            font-weight: 500;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &-remove:disabled {
        border-color: #c4c4c4;
        color: #c4c4c4;
    }
    &-search {
        &-wrapper {
            width: 260px;
            color: #8f8f8f;
            position: relative;
        }
        &-icon {
            position: absolute;
            left: 10px;
            top: 0;
            bottom: 0;
            margin: auto;
        }
        &-input {
            padding-left: 40px;
            border-color: #e5e1e5;

            &:hover,
            &:active,
            &:focus {
                border-color: #e5e1e5;
            }
        }
    }
    &-create {
        background-color: #444;
    }
}

@media (max-width: 1200px) {
    .card-header {
        flex-wrap: wrap;
        gap: 10px;
        padding: 15px 0 0;

        .col-auto {
            width: 100%;

            &:first-child {
                padding-bottom: 15px;
                text-align: right;
                border-bottom: 1px solid #e5e1e5;
            }

            &:last-child {
                padding-bottom: 10px;
            }
        }
    }

    .passports-search {
        &-wrapper {
            width: 100%;
        }
        &-input {
            border: none;
        }
    }

    .passports-item {
        .passports-item-edit,
        .passports-item-toggle,
        .passports-item-notification {
            display: none;
        }
        &-tools {
            display: block;
            cursor: pointer;
        }
        &-name,
        &-image {
            margin: 0 7px;
        }
    }

    .passports-item-icon {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 22px;
        flex-shrink: 0;
    }

    .passports-item-controls,
    .passports-item-notification {
        margin: 0;

        & > div {
            display: flex;
            align-items: flex-start;
            padding: 0;
            gap: 8px;
            margin-bottom: 10px;
            background: none;
            font-size: 10px;
            text-align: left;

            svg {
                position: relative;
                top: 1px;
            }

            &:first-child {
                margin-bottom: 10px;
            }
        }
    }

    .passports-item-notification-mobile {
        display: block;
        color: #D93F49;
        flex-shrink: 0;
    }

    .passports-item-controls {
        color: #000;
        & > div {
            cursor: pointer;
            margin-bottom: 10px;
            &:first-child {
                svg {
                    top: -3px;
                }
            }
        }
    }

    .passports-item-toggle {
        margin: 15px 0 0 25px;
    }

    .b-popover {
        width: 169px;
        max-width: 169px;
    }
}
</style>
