<template>
<div class="steps">
    <div v-for="(step, index) in list"
         :key="step.id"
         class="steps-item"
         :class="{ 'steps-item_active': step.isActive, 'steps-item_current': step.isCurrent }">
        <div class="steps-item-trigger" @click="$emit('set-step', step.id)">
            <IconCheck v-if="step.isActive" :size="16" />
            <span v-else>{{ index + 1 }}</span>
        </div>
        <div class="steps-item-label">{{ getText(step.id) }}</div>
    </div>
</div>
</template>

<script>
import IconCheck from "@/components/common/icons/IconCheck.vue";

export default {
    name: "Steps",
    components: { IconCheck },
    props: {
        list: {
            type: Array,
            required: true,
        },
    },
    methods: {
        getText(id) {
            switch(id) {
                case 'name':
                    return this.$t('pass.step1.label');
                case 'doors':
                    return this.$t('pass.step2.label');
                case 'users':
                    return this.$t('pass.step3.label');
                case 'design':
                    return this.$t('pass.step4.label');
                default:
                    return '';
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.steps {
    display: flex;
    align-items: flex-start;

    &-item {
        width: 40px;
        text-align: center;
        margin-right: 50px;
        position: relative;

        &:not(:last-child):after {
            content: '';
            display: block;
            width: 50px;
            height: 2px;
            background-color: #D9D9D9;
            position: absolute;
            left: 40px;
            top: 14px;
        }

        &_current:not(:last-child):after,
        &_active:not(:last-child):after {
            background-color: #E95325;
        }

        &-trigger {
            width: 30px;
            height: 30px;
            margin: 0 auto 10px;
            background-color: #D9D9D9;
            font-size: 14px;
            line-height: 30px;
            font-weight: 700;
            color: #929292;
            border-radius: 100%;
            cursor: default;

            .steps-item_active & {
                color: #E95325;
                background-color: #FEE0CF;
                cursor: pointer;
            }

            .steps-item_current & {
                background-color: #E95325;
                color: #FFF;
            }
        }

        &-label {
            margin: 0 -10px;
            width: 60px;
            font-size: 10px;
            line-height: 12px;
            font-weight: 500;
        }
    }
}

@media (max-width: 480px) {
    .steps-item {
        margin-right: 35px;

        &:not(:last-child):after {
            width: 35px;
            left: 40px;
        }
    }
}
</style>
