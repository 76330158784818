<template>
<div class="button-toggle" :class="{ 'button-toggle-active': isActive }" @click="onClick">
    <div class="button-toggle-decor"></div>
    <span v-if="isActive">{{ $t('pass.on') }}</span>
    <span v-else>{{ $t('pass.off') }}</span>
</div>
</template>

<script>
export default {
    name: "ButtonToggle",
    data() {
        return {
            loading: false,
        }
    },
    props: {
        isActive: Boolean,
    },
    methods: {
        onClick() {
            if (!this.loading) {
                this.loading = true;
                this.$emit('toggle-passport')
            }
        },
    },
    watch: {
        isActive() {
            this.loading = false;
        },
    }
}
</script>

<style lang="scss" scoped>
.button-toggle {
    display: flex;
    width: 75px;
    height: 22px;
    background-color: #D9D9D9;
    color: #8F8F8F;
    border-radius: 11px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    font-size: 12px;
    font-weight: 500;

    &-decor {
        width: 20px;
        height: 20px;
        background-color: #fff;
        border-radius: 50%;
        transition: .5s;
        position: absolute;
        left: 1px;
    }

    &.button-toggle-active {
        background-color: #C2E1B7;
        color: #69BE5A;

        .button-toggle-decor {
            left: 54px;
        }
    }
}
</style>
