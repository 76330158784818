<template>
    <svg
        :width="size"
        :height="size"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.99567 13.6708C9.279 13.3875 9.279 12.9625 8.99567 12.6791L5.52484 9.2083L13.4582 9.2083C13.8832 9.2083 14.1665 8.92497 14.1665 8.49997C14.1665 8.07497 13.8832 7.79163 13.4582 7.79163L5.52484 7.79163L8.99567 4.3208C9.279 4.03747 9.279 3.61247 8.99567 3.32913C8.71234 3.0458 8.28734 3.0458 8.004 3.32913L2.83317 8.49997L8.004 13.6708C8.28734 13.9541 8.71234 13.9541 8.99567 13.6708Z"
            fill="currentColor"/>
    </svg>
</template>

<script>
export default {
    name: "IconArrowLeft",
    props: {
        size: {
            type: Number,
            default: 24,
        },
    },
}
</script>
