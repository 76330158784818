<template>
    <div class="select-users">
        <IconSearch :size="18" class="select-users-icon" />
        <multiselect
            :value="selected"
            track-by="id"
            :placeholder="placeholder"
            :multiple="true"
            :options="options"
            :close-on-select="false"
            :show-labels="false"
            :customLabel="searchBy"
            :maxHeight="290"
            @input="selectedUpdated"
        >
            <template #selection="{ values, isOpen }">
                <span class="multiselect__single"
                      v-if="values.length"
                      v-show="!isOpen">{{ values.length }} {{ values.length === 1 ? selectedTextOne : selectedTextSeveral }}</span>
            </template>
            <template #option="props">
                <span class="multiselect-custom-option">
                    <span v-if="withOrder" class="multiselect-custom-option-label">
                        {{ getOptionLabel(props.option.id) }}
                    </span>
                    <span v-else class="multiselect-custom-option-label">
                        <IconCheck v-if="isChosen(props.option.id)" :size="14" />
                    </span>
                    <span v-if="withImages" class="multiselect-custom-option-image">
                        <img v-if="props.option.img" :src="props.option.img" :alt="props.option.label">
                    </span>
                    <span class="multiselect-custom-option-text">{{ props.option.label }}</span>
                </span>
            </template>
            <template #noResult><p class="m-0">{{ notFound }}</p></template>
        </multiselect>
        <ErrorMessage v-if="errorMessage" :text="errorMessage" />
        <div v-if="withTags" class="multiselect-custom-list">
            <span
                v-for="item in selected"
                :key="item.id"
                class="multiselect-custom-list-item"
                @click="unselectItem(item.id)">
                {{ item.label }}
                <span>x</span>
            </span>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import IconCheck from "@/components/common/icons/IconCheck.vue";
import ErrorMessage from "@/components/common/errorMessage.vue";
import IconSearch from "@/components/common/icons/IconSearch.vue";

export default {
    name: "SelectMultiselect",
    components: {IconSearch, ErrorMessage, IconCheck, Multiselect },
    data() {
        return {
            selected: [],
            options :[],
        }
    },
    props: {
        initialSelected: Array,
        initialOptions: Array,
        withOrder: Boolean,
        withTags: Boolean,
        withImages: Boolean,
        errorMessage: String,
        selectedTextOne: String,
        selectedTextSeveral: String,
        placeholder: String,
        notFound: String,
    },
    methods: {
        isChosen(id) {
            return !!this.selected.find((option) => option.id === id)
        },
        unselectItem(id) {
            this.selected = this.selected.filter(option => option.id !== id);
        },
        getOptionLabel(id) {
            const number =  this.selected.findIndex((option) => option.id === id);
            return number === -1 ? '' : number + 1;
        },
        selectedUpdated(val) {
            this.selected = val;
        },
        searchBy({ label }) {
            return `${label}`
        },
    },
    watch: {
        initialSelected(val) {
            this.selected = val;
        },
        initialOptions(val) {
            const list = [...val];
            list.sort((prev, next) => {
                const a = prev.label.toUpperCase();
                const b = next.label.toUpperCase();
                if (a > b) return 1;
                if (a < b) return -1;
                return 0;
            });
            this.options = list;
        },
        selected(val) {
            this.$emit('multiselect-selected', val);
        },
    },
}
</script>

<style lang="scss">
.multiselect__option--highlight {
    background-color: #f3f3f3;
    color: #35495e;
}
.multiselect__option--selected {
    &.multiselect__option--highlight {
        background-color: #f3f3f3;
        color: #35495e;
    }
    .multiselect-custom-option-label {
        background-color: #fee0cf;
    }
}
.multiselect__option {
    white-space: wrap;
}
.multiselect-custom {
    &-option {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        &-label {
            display: block;
            width: 20px;
            height: 20px;
            margin: 0 15px 0 0;
            background-color: #D9D9D9;
            font-size: 12px;
            line-height: 20px;
            font-weight: 700;
            color: #E95325;
            text-align: center;
        }

        &-image {
            display: block;
            width: 32px;
            height: 32px;
            margin: 0 15px 0 0;
            border-radius: 100%;
            overflow: hidden;
            background-color: #C4C4C4;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
    &-list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-content: flex-start;
        flex-wrap: wrap;
        gap: 18px;
        margin-top: 18px;
        font-size: 14px;
        font-weight: 500;
        line-height: 26px;

        &-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 26px;
            padding: 0 12px;
            border-radius: 4px;
            background-color: #D9D9D9;
            color: #000;
            cursor: pointer;

            span {
                display: block;
                width: 16px;
                height: 16px;
                margin-left: 5px;
                border-radius: 3px;
                text-align: center;
                font-size: 14px;
                line-height: 14px;
            }

            &:hover span {
                background-color: #888;
            }
        }
    }
}
.select-users {
    position: relative;
    color: #8f8f8f;

    &-icon {
        position: absolute;
        left: 15px;
        top: 11px;
        z-index: 100;
    }

    .multiselect__input {
        padding-left: 30px;

        &::placeholder {
            color: #8f8f8f;
            opacity: 1;
        }

        &::-ms-input-placeholder {
            color: #8f8f8f;
        }
    }

    .multiselect__placeholder {
        padding-top: 2px;
    }

    .multiselect__input,
    .multiselect__single,
    .multiselect__tags {
        padding-top: 4px;
    }

    .multiselect__single,
    .multiselect__placeholder {
        margin: 0;
        padding-left: 30px;
        font-size: 16px;
    }
}
</style>
