<template>
    <svg
        :width="size"
        :height="size"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g>
            <path
                d="M6.76736 3.81572L8.26361 3.80203L8.27731 5.29828L6.78106 5.31197L6.76736 3.81572ZM6.79475 6.80822L8.291 6.79453L8.33208 11.2833L6.83583 11.297L6.79475 6.80822ZM7.48125 0.0682501C3.3516 0.106045 0.030674 3.48832 0.0684687 7.61797C0.106263 11.7476 3.48854 15.0686 7.61819 15.0308C11.7478 14.993 15.0688 11.6107 15.031 7.48103C14.9932 3.35138 11.6109 0.0304554 7.48125 0.0682501ZM7.6045 13.5345C4.30526 13.5647 1.59491 10.9035 1.56472 7.60428C1.53452 4.30505 4.19571 1.5947 7.49495 1.5645C10.7942 1.53431 13.5045 4.1955 13.5347 7.49473C13.5649 10.794 10.9037 13.5043 7.6045 13.5345Z"
                fill="currentColor"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: "IconInfo",
    props: {
        size: {
            type: Number,
            default: 16,
        },
    },
}
</script>
