<template>
    <svg
        :width="size"
        :height="size"
        viewBox="0 0 44 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M39.875 9.09375C41.0667 10.3125 41.0667 12.375 39.875 13.6875L18.7 35.3438C17.5083 36.5625 15.4917 36.5625 14.2083 35.3438L4.12501 25.0312C2.93335 23.8125 2.93335 21.75 4.12501 20.4375C5.31668 19.2188 7.33335 19.2188 8.61668 20.4375L16.5 28.5L35.3833 9.09375C36.575 7.875 38.5917 7.875 39.875 9.09375Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    name: "IconCheck",
    props: {
        size: {
            type: Number,
            default: 24,
        },
    },
}
</script>
